@font-face {
    font-family: AmazonEmberBold;
    src: url('../vendor/font-amz/AmazonEmber_Bd.ttf');
}
@font-face {
    font-family: AmazonEmber;
    src: url('../vendor/font-amz/Amazon-Ember-Medium.ttf');
}

/* swagger css */
body {
    font-family: "Open Sans", sans-serif !important;
}
.swagger-ui {
    background:white;
    width: 95%;
    margin: auto;
}
.description {
    top: -28px;
    position: relative;
}
.swagger-ui .info .title {
    font-family: AmazonEmberBold !important;
    font-weight: 500 !important;
    line-height: 1.5 !important;
    color: #3b4151  !important;
    text-align: center;
    position: relative;
    top: 73px;
    margin-bottom: 0.5rem !important;
    font-size: 1.6rem;
    margin: auto;
    left: 46px;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
.error-message {
    color: #e74c3c; /* Rouge vif */
    font-size: 0.85em; /* Taille légèrement plus petite */
    display: flex;
    align-items: center; /* Pour aligner le texte et l'icône */
    margin-top: 0.3em;
}

.error-message i {
    margin-right: 0.5em; /* Espace entre l'icône et le texte */
    color: #e74c3c;
}

.swagger-ui .info hgroup.main a {
    visibility: hidden;
}
.swagger-ui .info .title small pre {
    font-family: AmazonEmber !important;
    color: #504aa4 !important;
}
.swagger-ui .info .title small {
    background: transparent;
}
.servers {
    font-weight: 300;
    line-height: 1.7;
    font-family: "Open Sans", sans-serif !important;
    color: #525f7f;
    visibility: hidden;
}
.swagger-ui select {
    border: 1px solid #41444e;
}
.prop-type {
    color: #2dce89 !important
}
.swagger-ui .info .renderedMarkdown p {
    text-align: left;
    font-size: 1rem !important;
    margin-top: 1.5rem;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 400 !important;
    line-height: 1.7 !important;
    color: #434355!important;
    position: relative;
    top: 54px;
    width: 100%;
}
.renderedMarkdown p {
    text-align: left;
    font-size: 1rem !important;
    margin-top: 1.5rem;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 400 !important;
    line-height: 1.7 !important;
    color: white;
}
.swagger-ui .info {
    font-family: inherit !important;
}
.swagger-ui .wrapper {
    font-family: inherit !important;
}
.swagger-ui .markdown p {
    font-family: inherit !important;
    font-weight: 600;
    line-height: 1.5;
    color: #32325d;
}
.information-container {
    background-color: #F4F4F7 !important;
    width: 100% !important;
}
.swagger-ui .wrapper {
    max-width: 100%;
}
.swagger-ui .opblock {
    width: 93%;
    margin: auto;
    position: relative;
    left: 38px;
}
.swagger-ui section.models {
    width: 88%;
    margin: 20px auto auto;
    font-family: inherit !important;

}
.swagger-ui section.models {
    border: 1px solid #172B4D;
}
.swagger-ui .btn.execute {
    background: linear-gradient(35deg, #9E191D 0, #d54630 100%) !important;
    color: #fff;
    border-color: #d54630;
}
.swagger-ui textarea {
    font-size: 14px;
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.7;
    font-family: inherit !important;
    overflow-x: auto;
    padding: 0.5em;
    background: rgb(51, 51, 51);
    color: white;
}
.swagger-ui section.models h4 {
    font-family: inherit !important;
}
.swagger-ui section.models .model-container {
    font-family: inherit !important;
    background: #E9ECEF;
}
.swagger-ui section.models.is-open h4 {
    margin: 0 0 5px;
    border-bottom: 1px solid rgba(59, 65, 81, 0.3);
    background-color: white;
}
.swagger-ui .opblock.opblock-put .opblock-summary-method {
    background: #59D883  !important;
    width: 85px;
}
.swagger-ui .opblock.opblock-put .opblock-summary {
   border: none;
}
.swagger-ui .opblock.opblock-put {
    border: none;
    width: 110%;
    position: relative;
    left: -31px;
}
.swagger-ui .opblock.opblock-get{
    position: relative;
    width: 110%;
    left: -33px;
}

.swagger-ui .opblock.opblock-post {
    border-color: #41444e !important;
    background: white;
    color: white;
    border: none;
    padding: 0px;
    margin: 0px;
    position: relative;
    /* right: -35px; */
    width: 110%;
    left: -33px;
}
.swagger-ui .opblock .opblock-summary-path__deprecated {
    color: black;
}
.swagger-ui .opblock.opblock-deprecated .opblock-summary-method {
    background: #282626 !important;
    border-color: #41444e !important;
}
.swagger-ui .opblock-title_normal {
    font-size: 15px;

    color: #ff0008;
}
.swagger-ui .opblock.opblock-deprecated{
    opacity: .6;
    background: hsla(0,0%,92.2%,.1);
    left: -33px;
    border: none;
    padding: 0px;
    margin: 0px;
    /* top: 5px; */
    margin-top: 5px;
    margin-bottom: 3px;
    width: 110%;
}
.swagger-ui .opblock.opblock-post .opblock-summary-method {
    background: linear-gradient(35deg,#9e191d,#cc321a)!important;
    width: 85px;
}
.swagger-ui .opblock.opblock-post .opblock-summary {
    border-color: #ebebeb  !important;
}
.swagger-ui .opblock.opblock-post .tab-header .tab-item.active h4 span:after {
    background: #FA441B !important;
}
.swagger-ui .opblock.opblock-get .opblock-summary-method {
    background:#dbd433  !important;
    width: 85px;
}
.swagger-ui .opblock.opblock-get .opblock-summary {
    border-color: #41444e !important;
}
.swagger-ui .opblock.opblock-get {
    border-color: #ebebeb;
    background: white;
}

.swagger-ui .opblock.opblock-delete .opblock-summary-method {
    background: linear-gradient(35deg, #F5365C 0, #b97b87 100%) !important;
    width: 85px;
}
.swagger-ui .opblock.opblock-delete .opblock-summary {
    border-color: #41444e;
}
.swagger-ui .opblock.opblock-delete {
    font-family: inherit !important;
    background: white;
    border: none;
    position: relative;
    left: -32px;
    width: 110%;
}

.swagger-ui .opblock.opblock-patch .opblock-summary-method {
    background: linear-gradient(180deg,#0065FB 0,#0065FB) !important;
    width: 85px;
}
.swagger-ui .opblock.opblock-patch .opblock-summary {
    border-color: #41444e;
}
.swagger-ui .opblock.opblock-patch {
    font-family: inherit !important;
    background: white;
    border: none;
    position: relative;
    left: -32px;
    width: 110%;
}

.swagger-ui .scheme-container .schemes {
    font-family: inherit !important;
}
.opblock-body {
    font-family: inherit !important;
}
.swagger-ui .scheme-container {
    background: transparent !important;
    margin: auto;
    padding: 10px;
    position: relative;
    bottom: 129px;
    width: 83%;
    height: 79px;
    /*border: 1px solid #453d3d;*/
    box-shadow: none;
}
/* .swagger-ui .markdown p, .swagger-ui .markdown pre, .swagger-ui .renderedMarkdown p, .swagger-ui .renderedMarkdown pre {
    font-family: inherit !important;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    color: #525f7f;
    text-align: left;
    background-color: #fff;
    text-transform: uppercase!important;
} */


/* .section-shaped .shape-style-1.shape-default {
    background: linear-gradient(150deg, #313d4b 23%, #1c5ba1 70%, #34383c 94%);
} */
.feedback {
    margin-top: 70px;
}
.main-feature {
    margin-top: -135px;
}
.information-container {
    background: transparent !important;
    width: 100% !important;
}
.swagger-ui .opblock-tag {
    font-size: 13px;
    font-weight: bold;
    line-height: 1.5;
    color: #434355!important;
    width: 110%;
    left: -33px;
    position: relative;
}

.section-bg {
    background-color: #F1F4F8;
    background-image: linear-gradient(315deg,#F1F4F8,#f5f7fa 75%);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: cover;
    /*background: #000000;*/
    padding-top: 5rem;

}
.section-lg{
    padding-top: 5rem
}
.swagger-ui .btn.authorize {
    color: #071739;
    border-color: transparent;
    background: transparent;
    text-transform: uppercase;
    will-change: transform;
    letter-spacing: 0.025em;
    font-family: AmazonEmber;
    position: relative;
    right: 45%;
    top: -135px;
    line-height: 1.6;
    width: max-content;
    border: none !important;
    font-weight: 500;
}
.swagger-ui .btn.authorize svg {
fill: #fff;
}
.swagger-ui section.models h4 {
font-size: 1.1rem;
margin-bottom: 0.5rem;
font-family: inherit;
font-weight: 400;
line-height: 1.5;
font-family: inherit !important;
color: #fff;
}

.swagger-ui section.models .model-container {
font-family: inherit !important;
background: transparent;
color: white;
}
.swagger-ui section.models.is-open h4 {
margin: 0 0 5px;
border-bottom: 1px solid rgba(59, 65, 81, 0.3);
background: transparent !important;
}
.swagger-ui .scheme-container .schemes {
font-family: inherit !important;
}
.servers-title {
font-size: 1.25rem !important;
margin-bottom: 0.5rem !important;
font-family: inherit;
font-weight: 400 !important;
line-height: 1.5 !important;
font-family: inherit !important;
color: #FFFFFF;
visibility: hidden;
}
.ws-list-icon-i {
color: white;
}
.ws-list-icon-bg {
/*background: linear-gradient(35deg, #9E191D 0, #d54630 100%) !important;*/
    background: transparent;
    position: relative;
    top: 56px;
    right: 76px;
}
.ws-list {
    position: relative;
    top: 219px;
    color: white !important;
    left: 185px;
}
.swagger-ui .auth-btn-wrapper .authorize {
    left: -120px !important;
    position: relative;
}
.swagger-ui .btn.authorize span {
    position: relative;
    top: -2px;
}
.btn-authorise-image{
    position: relative;
    top: 20px;
    margin-top: 16px !important;
    visibility: hidden;
}
.swagger-ui .wrapper {
    margin: auto;
    max-width: 100%;
    position: relative;
    bottom: 69px;
    height: 100%;
    right: 16px;
}
.ws-list h3 {
    color: white !important;
    font-family: 'AmazonEmber' !important;
    font-size: 1.5rem !important;
    font-weight: 400;
    line-height: 1.3 !important;
    position: relative;
    top: 7px;
    left: 98px;
}
.ws-list-cat-icon {
    position: relative;
    left: 87px;
    margin-bottom: 14px;
}
.model-title {
    font-size: 1rem !important;
    margin-bottom: 0.5rem !important;
    font-family: inherit;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    font-family: inherit !important;
}
.swagger-ui .model-title {
    color: #F07003;
}
.model .property-row {
    font-size: 1rem !important;
    margin-bottom: 0.5rem !important;
    font-weight: 300 !important;
    line-height: 1.5 !important;
    font-family: inherit !important;
    color: white;
}
.bg-gradient-default {
    background: white !important;
}
.swagger-ui section.models {
    border: 1px solid rgba(59, 65, 81, 0.3);
}

.swagger-ui section.models .model-box {
    background: transparent;
    color: #3B4151;
}
.swagger-ui .code,
.swagger-ui code {
    font-family: Consolas, monaco, monospace;

}
.swagger-wrap {
    background: #2B2B2B !important;
}
.opblock-section .opblock-section-request-body .model-box {
    background: #333333 !important;
    overflow: scroll;
    overflow-x: visible;
    width: 100%;
}
.response .renderedMarkdown p {
    color: #ca221e !important;
}
.opblock-section .opblock-section-request-body .model-box .renderedMarkdown p {
    color: white !important;
}

.response .model-box {
    background: #333333 !important;
}

.response .model-box .renderedMarkdown p {
    color: white !important;
}
.brace-open {
    color: white !important;
}
.swagger-ui .model-toggle:after {

    background: url("data:image/svg+xml;utf8, <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' fill='white'/></g></svg>") 50% no-repeat;
}
.swagger-ui .authorization__btn.unlocked {
    opacity: 1;
}
svg {
    fill: white !important;
}
.unlocked svg {
    fill: #E04BE1 !important;
    visibility: hidden;
}

.swagger-ui .response-control-media-type--accept-controller select {
    border-color: #41444e;
}
.brace-close {
    color: white !important;
}
.swagger-ui .model .property.primitive {
    color: #c2c0c0;
}

/***/
.main-bg {
    background-image: url('../img/bg.jpg') !important;
}
.headroom--pinned {
    background: #F9FBFD !important;
}

.navbar-transparent {
    background-color: #f6f6f6 !important;
}
.main-sub-bg {
    position: relative;
    top: 70px;
    padding: 0;
    text-align: left;
    z-index: -1 !important;
    height: 249px;
}
.locked svg {
    fill: #111111 !important;
}
.btn-explorer:hover{
    background: linear-gradient(35deg, #9E191D 0, #cc321a 100%) !important;
    border-color: #cc321a !important;
    color: white !important;
    opacity: 0.9;
}

.btn-explorer {
    background: linear-gradient(35deg, #9E191D 0, #cc321a 100%) !important;
    border-color: #cc321a;
    position: relative !important;
    top: 14px !important;
    left: 207px;
    color: white;
}
.btn-user-guide:hover {
    background: linear-gradient(180deg,#f67c1b 0,#e15500) !important;
    border-color: #9e191d !important;
}
.btn-user-guide {
    background: linear-gradient(180deg,#f67c1b 0,#e15500) !important;
    border-color: #525f7f;
    position: relative !important;
    top: 14px !important;
    left: 212px;
}
.btn-contact-us {
    background: linear-gradient(35deg, #9E191D 0, #cc321a 100%) !important;
    border-color: #9E191D;
}
.btn-explorer:hover {
    background: #f67c1b;
    border-color: #f67c1b;
}
.section2 {
    margin-top: -29px !important;

}
.title_bg {
    height: 96px;
    position: relative;
    right: 14px;
    top: 65px;
    visibility: hidden;
}
.main-section-content {
    margin-left: 76px;
    position: relative;
    top: -59px;
}

.feature {
    margin-right: 10px;
    position: relative;
    bottom: 40px;
    width: 490px;
    right: 14px;
    mix-blend-mode: multiply;
}
.hps-creation-date {
    color: #B61316;
    margin-top: 10px;
    text-align: center;
}
.testmonials {
    background: #071739 !important;
}
.contact-us {
    background: #071739 !important;
}
.swagger-ui .response-control-media-type__accept-message {
    color: #111111;
    font-size: 0.7em;
}
.response-control-media-type__accept-message code {
    color: #B61316;
}
.testmonials-image {
    width: 450px;
    height: 203px;
    margin-top: -336px;
}

.text-zone {
    margin-top: -55px;
}
.card-p {
    top: -47px;
}
.bg-gradient-success {
    background: linear-gradient(35deg, #9E191D 0, #d54630 100%) !important;
}
.text-success {
    color: #9E191D !important;
}
.text-primary {
    color: #9E191D !important;
}
.footer {
    background: #071739;
    padding: 1.5rem 0;
}
.footer-first-txt {
    color: white !important;
}
.footer-second-txt {
    color: #d5d5da !important;
}
.copyright-link {
    color: #d5d5da !important;
}
.footer .copyright{
    color:whitesmoke;
}
.text-1 {
    margin-top: 0;
}
.swagger-ui section.models {
    width: 96%;
    position: relative;
    left: 42px;
}
.swagger-ui .prop-enum {
    display: block;
    color: wheat;
}
.renderedMarkdown img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07) !important;
    border-radius: 0.25rem !important;
    max-width: 100%;
    height: auto;
    cursor: zoom-in;
}
.swagger-ui .parameter__name.required:after
{
    color: red;
}
.renderedMarkdown:hover img {}
.swagger-ui b,
.swagger-ui strong {
    font-weight: inherit;
    font-weight: bolder;
    color: #1b7bc4;
}
.swagger-ui .errors-wrapper .errors h4 {
    color: #3b4151 !important;
}
.swagger-ui .errors-wrapper .errors small {
    color: #ffffff;
}
.swagger-ui .errors-wrapper hgroup h4 {
    color: #3b4151 !important;
}
swagger-ui .errors-wrapper .errors h4{
    color: #434355 !important;
}

.swagger-ui .errors-wrapper .errors .message {
    color: #434355;
}
.swagger-ui .errors-wrapper .errors__clear-btn {
    color: #434355;
    border-color: #434355;
}
.language-bash {
    background: transparent !important;
}
.locked svg {
    fill: #E04BE1 !important;
}

/* Modal */
.swagger-ui .auth-container input[type=password],
.swagger-ui .auth-container input[type=text] {
    min-width: 230px;
    width: 564px;
    position: relative;
    top: 34px;
    border-color: #ca0202;
}
.swagger-ui .auth-container .wrapper label {
    position: relative;
    top: 33px;
}
.swagger-ui .opblock-tag {
    border-bottom: 1px solid #ebebed;
    background: #fcfcfc;
}
.swagger-ui section.models .model-container {
    border: 1px solid #202020;
}
.no-uppercase {
    text-transform: none !important;
}
.swagger-ui .info .title small.version-stamp {
    background-color: #555454;
    visibility: hidden;
}
.version-page{
    background-image: url('../img/bg-pattern.jpg') !important;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: cover;
}
.swagger-ui .parameters-col_description .renderedMarkdown p {
    color: #4f4f4f;
}


/********** Select Version ********/
.profile-page .card-profile {
    margin-top: -150px;
    height: auto;
    position: relative;
    top: -124px;
    background: transparent !important;
    border:none;
}
.profile-page .card-profile .card {
    position: relative;
    top: 62px !important;
    /*left: 161px !important;*/
    background: #EDEEEE;
    color: rgb(255, 255, 255);
    font-family: inherit !important;
    border: 1px solid rgb(34, 33, 33) !important;
    height: 300px;
    margin-bottom: 20px;
    border-top: 5px solid #9e191d !important;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.3), 0 0 0 1px rgba(0,0,0,.04);
}
.pg-version-btn-explorer {
    background: linear-gradient(35deg, #9E191D 0, #cc321a 100%) !important;
    border-color: #9E191D;
    left: 138px;
    top: -57px;
    text-transform: none;
}
.btn-primary:hover {
    border-color: #9E191D !important;
}
.profile-page .section-shaped .shape-style-1.shape-default {
    background: #303E44;
}

.profile-page h2 {
    color: #2A206A;
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    position: relative;
    top: 0px;

}
.profile-page{
    background: #F1F4F8;
}
.version-title-wrap {
    background: transparent;
    font-weight: 600;
}
.users-title-wrap {
    font-size: 15px !important;
    display: flex;
    width: 100%;
    text-align: center;
    background: transparent;
    margin: auto;
}
.profile-page .icon-shape-primary {
    color: #030303;
    background-color: rgb(102 99 99 / 50%);
}
.profile-page h2 ~ p {
    position: relative;
    top: 15px;
    left: 13px;
    text-align: left;
    color: #506690 !important;
    font-family: AmazonEmber;
    line-height: 1.6;
    font-weight: 400;
    font-size: 14px;
    padding-right: 40px;
    padding-left: 20px;
}
.module-list{
    padding-left: 37px;
    padding-right: 18px;
    text-align: justify;
}
.badge-primary {
    color: rgb(255, 255, 255);
    background: linear-gradient(35deg,#9e191d,#cc321a)!important;
}
.main-version-bg {
   /* background-image: url('../img/theme/bg-swagger4.jpg') !important;*/
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: cover;
}
.section2-bg {
    background: rgb(179, 61, 61) !important;
}
.version-sep {
    height: 5px !important;
    background-color: #434057;
    margin: 0px !important;
}
.select-api-version-sep {
    height: 5px !important;
    background-color: #5F56BB;
    position: relative;
}
.section2-bg {
    background-image: url('../img/theme/bg-swagger4.jpg') !important;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: cover;
}
.register-bg {
    background-image: url('../img/bg-register.jpg') !important;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: cover !important;
}
.login-bg {
    background-image: url('../img/bg.jpg') !important;
    background-repeat: round !important;
    background-origin: content-box;
    background-size: cover !important;
}
.section2-bg {
    background-color: #ffffff;
}
.swagger-ui .auth-btn-wrapper button {
    position: relative;
    top: -10px !important;
}

.pg-version-sub-title {
    color: #506690 !important;
    font-family: AmazonEmber;
    /*font-size: 1.2rem;*/
    line-height: 1.6;
    font-weight: 400;
}

.pg-version-card-desc {
    color: #717a7a !important;
    font-family: AmazonEmber;
    font-size: 0.8rem;
    line-height: 1.5;
    font-weight: 500;
    position: relative;
    top: 14px;
    height: 93px;
    text-align: justify;
}
.version-card-desc {
    color: #717a7a !important;
    font-family: AmazonEmber;
    font-size: 0.8rem;
    line-height: 1.6;
    text-align: justify;
    height: 82px;
}
.txt-center{
    text-align: center !important;
}
.btn-create-acc:hover {
    background: #2A206A !important;
    border-color: #2A206A !important;
}
.btn-create-acc {
    background: #2A206A !important;
    color: white !important;
    height: 33px;
    -webkit-font-smoothing: antialiased;
    border: 1px solid white;
    border-radius: 3px;
    font-weight: 500;
    border-color: #e76d0c #e15500;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.2);
    font-size: 13px;
    vertical-align: middle;
    line-height: 1.43;
    font-family: AmazonEmberBold;
    padding-top: 6px;
}
.btn-save-acc:hover{
    border-color: #3524a4 !important;
}
.btn-save-acc {
    background: linear-gradient(180deg,#3524a4 0,#2A206A ) !important;
    -webkit-font-smoothing: antialiased;
    border: 1px solid;
    border-radius: 3px;
    font-weight: 500;
    border-color: #3524a4;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.2);
    font-size: 13px;
    vertical-align: middle;
    line-height: 1.43;
    font-family: AmazonEmberBold;
    width: 289px;
}
.btn-login-acc {
    background: linear-gradient(180deg,#f67c1b 0,#e15500) !important;
    -webkit-font-smoothing: antialiased;
    border: 1px solid;
    border-radius: 3px;
    font-weight: 500;
    border-color: #e76d0c #e15500;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.2);
    font-size: 13px;
    vertical-align: middle;
    line-height: 1.43;
    font-family: AmazonEmberBold;
    width: 300px;
}
.create-acc-card {
    background: #EDEEEE !important;
    border-top: 5px solid #9e191d !important;
}

.create-acc-title {
    position: relative;
    right: 95px;
    top: 52px;
    color: white;
    font-weight: bold;
}

.create-acc-sub-title {
    color: #9dacc7 !important;
    font-family: AmazonEmber;
    font-size: 1.2rem;
    line-height: 1.6;
    font-weight: 400;
    position: relative;
    right: 95px;
    top: 62px;
    text-align: justify;
}
.text-register {
    color: #19191a !important;
    font-family: AmazonEmber;
    font-size: 1.2rem;
    line-height: 1.6;
    font-weight: 400;
}
.form-control{
    color: black !important;
}
.register-signin {
    position: relative;
    top: 20px;
    text-align: center;
    color: rgb(0, 0, 180);
    left: 5px;
}
.form-position {
    position: relative;
    top: 18px;
}
.main-version-sub-bg {
    /*background-image: url('../img/theme/bg-swagger4.jpg') !important;*/
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: cover;
    position: inherit;
    padding-top: 0;
    padding-bottom: 0;
}
.main-module-sub-bg {
   /* background-image: url('../img/theme/bg-swagger4.jpg') !important;*/
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: cover;
    position: inherit;
    padding-top: 0;
    padding-bottom: 0;
}
.line-sep {
    position: relative;
    top: 20px;
    width: 97%;
    left: 14px;
    height: 1px;
}
.line-sep-swagger{
    width: 74%;
    position: relative;
    top: 9px;
    margin: auto;
    left: 40px;
}
#errorMessage {
    color: red;
}
.btn-warning{
    border-color: #071739 !important;
    color: white !important;
    background: #2A206A !important;
}
.fa{
    color:#2A206A ;
}
.fa-search{
    color: white !important;
}
.btn-sign-out {
    background: transparent;
    height: 33px;
    -webkit-font-smoothing: antialiased;
    /* border: 1px solid; */
    border-radius: 3px;
    font-weight: 500;
    /* border-color: #e76d0c #e15500; */
    /* box-shadow: inset 0 1px 0 hsla(0,0%,100%,.2); */
    font-size: 13px;
    vertical-align: middle;
    line-height: 1.43;
    font-family: AmazonEmberBold;
    padding-top: 6px;
}
.modal-content {
    background: #feffff;
    color: black;
}
.modal-message {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    position: relative;
    top: 5px;
    color: black;
}
.modal-message-error {
    text-align: center;
    font-weight: 500;
}

.alert .alert-inner--text {
    display: block;
}
.modal-title-error {
    color: red;
}
.modal-body {
    padding: 1rem;
}

.modal-header {
    padding: 1rem;
    border-bottom: none;
}
.modal-footer {
    border-top: 0;
    padding: 0;
}
.btn-close-modal {
    position: relative;
    top: -14px;
    right: 18px;
}
.has-error{
    border: 1px solid red;
}
.current-user{
   color: #071739 ;
   margin-right: 20px;
   margin-top: 5px;
    position: relative;
    left: 47%;
}
.alert-default {
    background-color: transparent;
}
.icon-shape {
    color: black;
    margin-left: 20px;
}
.module-name{
    font-weight: 500;
    font-size: 15px;
    vertical-align: middle;
    line-height: 1.43;
    font-family: AmazonEmber;
    position: relative;
    left: 64px;
    top: 40px;
}
.version-name{
    font-weight: 600;
    font-size: 12px;
    line-height: 1.93;
    font-family: AmazonEmber;
    position: relative;
    left: 85px;
    bottom: 11px;
}
.hide-search{
    visibility: hidden;
}
.v4-btn-select-icon{
    position: relative;
    right: 12px;
    top: 2px;
}
.v35-badge-primary {
    background: linear-gradient(35deg,#9e191d,#cc321a) !important; ;
    border-color: #525f7f;
    color: white !important;
}
.v4-badge-primary {
    background:linear-gradient(35deg, #31208c,#2A206A)!important;
    color: white !important;
}
.v4-product-description{
    position: relative;
    top: 10px;
}
.profile-page .card-profile .card{
    border-top: 5px solid #08255d !important;
}
.v4-product-btn-select-wrap{
    position: relative;
    width: 60%;
    margin: auto;
    top: 10px;
}

.v4-product-btn-select{
    border-color: #adb5bd;
    position: relative;
    margin: auto;
    width: 280px;
    z-index: 1000;
    text-transform: none;
    background:  linear-gradient(35deg, #31208c,#2A206A)!important
}
.v35-product-btn-select:hover{
    background: linear-gradient(35deg,#9e191d,#cc321a)!important;
    border-color: #525f7f;
   opacity: 0.7;
}
.v35-product-btn-select{
    background: linear-gradient(35deg,#9e191d,#cc321a)!important;
    border-color: #525f7f;
    position: relative;
    margin: auto;
    width: 280px;
    z-index: 1000;
    text-transform: none;
}
.v4-product-title{
    align-items: center;
    width: 48%;
    margin: auto;
}
.product-name{
    font-weight: 600;
    font-size: 12px;
    line-height: 1.93;
    font-family: 'AmazonEmberBold';
    position: relative;
}
.module-icon{
    position: absolute;
    height: 40px;
    left: 100px;
}
.btn-select-module:hover {
    background: #2100D9;
    border-color: #525f7f;
    opacity: 0.6;
}
.btn-select-module {
    background: linear-gradient(35deg,#9e191d,#cc321a)!important;
    border-color: #525f7f;
    position: relative;
    margin: auto;
    width: 261px;
    bottom: 28px;
    z-index: 1000;
    text-transform: none;
}
.is-deprecated{
    background-color: #535355;
    color: white;
}
.badge-hidden{
    visibility: hidden;
}
.alert-warning {
   background-color: #e11a20;
}
.card-lift--hover:hover {
     transform: none !important;
     transition:none !important;
}
.lead-justify{
    text-align: justify;
}
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='000' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/></svg>");
    position: relative;
    left: 50px;
}
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/></svg>");
    position: relative;
    right: 18px;
}
.carousel-inner{
    padding: 20px !important;
}
.carousel{
    position: relative;
    left: -18px !important;
}
.carousel-item{
    text-align: justify;
}
.card-w{
    width: 700px;
}

.carousel-indicators li {
    background-color: #7a0404;
    position: relative;
    top: 20px;
}

 .nav-item {
    margin-right:0px !important;
}
.icon-position{
    position: relative;
    top: -6px;
}
.py-style{
    padding-top: 1rem !important;
}
.v-lable{
    color: #c2bebe;
    position: relative;
    top: 10px;
    text-align: center;
}
.search-input-col{
    position: relative;
    top: 50px;
    margin: auto!important;
    display: none;
}
.search-input-warp{
    display: inline-flex;
}
.search-input{
width: 460px;
}
.btn-search{
    margin-left: 20px;
    background-color: #fa441b;
    border-color: #fa3a0e;
}
.opblock-section .opblock-section-request-body .model-box .renderedMarkdown table {
    color: white !important;
    border: 1px solid white;
}
.opblock-section .opblock-section-request-body .model-box .renderedMarkdown table tr {
    color: white !important;
    border: 1px solid white;
}
.create-account-link{
    position: relative;
    bottom: 52px;
    right: 13px;
    color: #0825f0;
}
.forgot-password{
    position: relative;
    bottom: 52px;
    left: 13px;
    color: #0825f0;
}
.swagger-ui svg:not(:root) {
    overflow: hidden;
    fill: rgb(114, 111, 111) !important;
}
.swagger-ui .dialog-ux .modal-ux {
    background: #e9ecef !important;
}
.swagger-ui .auth-btn-wrapper button {
    left: 159px;
}
.swagger-ui .auth-container .wrapper label {
    visibility: hidden;
}

.swagger-ui .dialog-ux .modal-ux-content h4 code {
  display: none;
}
.users-wrap{
    bottom: 21px !important;
}

.juOmcw svg{
    fill: #171717 !important;
}

.juOmcw:disabled svg{
    cursor: unset;
    color: #ebe8e8 !important;
    fill: #e2e1e1 !important;
}

.rdt_Pagination{

}
.rdt_TableHeader{
    background: transparent !important;
    color: #5F56BB !important;
}

.iiIynX{
    font-weight: bold !important;
}
.users-buttons{
    position: relative;
    top: 34px;
    margin-bottom: 60px;
    right: 27px;
}
.main-h{
    height: 800px !important;
}
.user-btn{
    display: inline-block;
    float: right;
    margin-right: 20px;
    position: relative;
    top: 6px;
    background: #2A206A  !important;
}
.rdt_TableHeadRow{
    background-color: #F6F6F6 !important;
    font-weight: bold !important;
  }
.rdt_TableCol_Sortable{
    font-weight: bold !important;
}
.rdt_TableCell{
    font-weight: 400 !important;
}
.edit-user{
    position: relative;
    top: 25px;
    background: transparent;
    padding-top: 19px;
    padding-left: 36px;
    padding-right: 36px;
}

.custom-toggle input:checked + .custom-toggle-slider:before {
    background: #2A206A  !important;
}
.enable-user-title{
    color: black;
    display: block;
    margin-top: 35px ;
    margin-bottom: 10px;
}
.enable-title{
    color: black;
    text-align: center;
    padding-bottom: 6px;
}
.custom-toggle input:checked + .custom-toggle-slider {
    border: 1px solid #5F56BB;
}
.user-btn-return{
    display: inline-block;
}

.update-form-has-error{
    border: 3px solid #ff0000;
    padding: 0px;
    margin: 0px;
    border-radius: 9px;
}
.update-form-lbl{
    color: black;
}
.ws-list-cat-icon {
    position: relative;
    left: 87px;
    margin-bottom: 5px;
    height: 50px;
}
.swagger-ui .dialog-ux .modal-ux-content h4 {
    display: none;
}
.swagger-ui .auth-container .wrapper label {
     visibility: visible;
}
.swagger-ui .auth-container .wrapper .renderedMarkdown div{
    position: relative;
    top: 36px;
}
.swagger-ui .auth-container .wrapper .renderedMarkdown div h5{
   color: #d54630;
}

.swagger-ui .auth-container .wrapper .renderedMarkdown div table tbody tr td:first-of-type {
   padding: 0px;
   min-width:0px;
   font-size: 12px;
    font-weight: 700;
    font-family: sans-serif;
    color: #3b4151;
}
.swagger-ui .auth-container .wrapper .renderedMarkdown div table tbody tr td:last-child {
    padding: 0px;
    min-width:0px;
    font-size: 12px;
     font-weight: 700;
     font-family: sans-serif;
     color: #3b4151;
 }
 .btn-info:hover {
    border-color: #d54630 !important;
}

.swagger-ui .response-col_description__inner tbody > tr {
    border: 1px solid rgb(153, 153, 153) ;
    padding: 220px;
    color: black;
    font-size: 14px;
    font-weight: 700;
    padding: 5px 40px 5px 10px;
    border: 1px solid #41444e;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
    font-family: sans-serif;
    color: #3b4151;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.swagger-ui .response-col_description__inner table tbody th {
    padding-left: 20px;
    font-size: 12px;
    color: brown;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 700;
    border: 1px solid grey;
}

.swagger-ui .response-col_description__inner table tbody tr td {
    padding-left: 20px;
    font-size: 11px;
    line-height: 0;
    border: 1px solid grey;
}
.swagger-ui .response-col_description__inner tbody {
    border: 1px solid grey;
    padding: 220px;
    color: black;
    border-width: 1px 0;
    white-space: normal;
    font-size: 1em;
    box-shadow: 3px 5px 5px  grey;
    border-radius: 3px;

}
.cust-icon{
    font-size: 16px;
}
.last-update{
    font-size: 13px;
    margin-left: 10px;
    font-weight: 400;
    margin-top: 5px;
    color: #9dacc7;
    position: relative;
    top: 299px;
}
.pwc-version{
    position: relative;
    top: 243px;
}
.modal-locked-message{
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    position: relative;
    color: black;
    width: 450px;
    right: 11px;
}
.cust-sandbox-icon{
    padding-right: 9px;
    position: relative;
    top: 1px;
    color: white;
}
.swagger-ui .dialog-ux .modal-ux-content h6 {
    display: none;
}
.language-json{
    background: none !important;
}
code[class*=language-], pre[class*=language-] {
    background: none !important;
}
.swagger-ui .highlight-code {
    position: relative;
    width: auto !important;
    overflow: overlay !important;
    max-width: 100%;
}
.swagger-ui .response-col_description {
    width: 100%;
    position: relative;
    right: 7px;
}
.swagger-ui table tbody tr td:first-of-type{
    min-width: 4em;
}
.swagger-ui .copy-to-clipboard {
    bottom: 23px !important;
}
.swagger-ui .download-contents {
    bottom: 23px !important;
}
.swagger-ui .response-col_links {
    display: none;
}
.bg-gradient-danger {
    background: linear-gradient(35deg
    , #f5a036 0, #f56036 100%) !important;
}
.help-modal{
    max-width: 804px;
    height: 500px;
}
.collapsible-wrap{
    margin-top: 25px;
}
.Collapsible__trigger {
    display: block;
        color: white;
        color: white;
        height: 35px;
        padding-left: 15px;
        padding-top: 5px;
        border: 1px solid #3b4151;
        border-radius: 7px;
        font-size: 14Px;
        margin-bottom: 10px;
        cursor: pointer;
}
.help-title{
    color: white;
    background:linear-gradient(
        180deg
        ,#f67c1b 0,#e15500) !important;
        border-radius: 5px;
        font-size: 14Px;
        padding-left: 15px;
        padding-top: 5px;
        height: 35px;
}
.Collapsible__contentOuter{
    background: white;
    color: black;
}
.collapsible-wrap .nav-link:hover{
    color: #f67c1b ;
    text-decoration: none;
    background-color: transparent;
    border-left-color: #f67c1b  !important;
    border-left-style: solid;
}
.collapsible-wrap .nav-link{
 font-size: 15px;
}
.help-spec-title{
    color: #f67c1b;
}
.getting-started{
    padding-bottom: 10px;
}
.help-txt-color{
    margin-top: 10px;
    color: #c5d0d1 ;
    font-family: AmazonEmber;
    font-size: 13px;
}
.main-help{
height: 4500px;
}
.help-wrap{
    bottom: 209px !important;
    height: 740px !important;
    position: relative;
    background: transparent;
}
#line1,#line2,#line3,#line4,#line5{
    margin-top: 4px;
    margin-bottom: 70px;
}

.top-btn{
    float: right;
    font-size: 25px;
    color: white;
    cursor: pointer;
}
.top-btn:hover{
    opacity: 0.5;
}
.help-img{
    background-clip: border-box;
    border-radius: 0.25rem;
    background-color: #000000;
    border: 1px solid #2b2b2b;
    filter: blur(10px) brightness(-100);
    -webkit-filter: blur(10px) brightness(-100);
}
.card-info{
    background: #201f1f;
}
.intro-detail{
    font-size: 12px;
    font-weight: bold;
    color: white;
}
.intro-detail-content{
  color: #c5d0d1;
  font-size: 13px;
  padding-left: 23px;
}
.breadcrumbs-swagger-wrap{
    background: transparent;
    position: relative;
    top: 14px;
    height: 25px;
    display: block;
    left: 15%;
}
.breadcrumbs-wrap{
    background: transparent;
    position: relative;
    top: 33px;
    height: 35px;
    display: block;
    left: 15%;
}
.breadcrumb {
    background: transparent !important;
    font-size: 14px;
}
.breadcrumb-item a{
    color: #071739;
    cursor: pointer;
    font-weight: 600;
}
.breadcrumbs-swagger{
    display: table-column;
}
.swagger-ui .opblock .opblock-summary-path{
 font-size: 14px;
}
.portal-logo{
    width: 557px;
    height:164px;
    position: relative;
    right: 77px;
    bottom: 30px;
}
.portal-logo-wrapper {
    display: inline-block;
}
.search-date-input {
    width: 200px;
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
}

.p-calendar {
    width: 100%;
}


.register-portal-logo{
    width: 557px;
    height:164px;
    position: relative;
    right: 77px;
    bottom: 30px;
}
.card-module{
    height: 100px  !important;
    cursor: pointer;
}
.card-module:hover{
    opacity: 0.7;
}
.btn-card-select-module{
  bottom: 0px;
  top: 10px;
}
.module-card-icon{
    position: absolute;
    height: 78px;
    left: 11px;
    top: 6px;
}
.module-card-name{
    font-weight: 500;
    font-size: 15px;
    vertical-align: middle;
    line-height: 1.43;
    font-family: AmazonEmber;
    position: relative;
    left: 121px;
    top: 4px;
    color: #f3eadd !important;
}
.no-available-module{
    color: #aab7b8 !important;
    font-family: AmazonEmber;
    position: relative;
    top: 24px;
    line-height: 1.6;
    font-weight: 400;
    left: 14px;
}

.module-badge{
        color: #000;
}
.profile-page .card-profile .card-module{
    top: 0px !important;
    background: #EDEEEE;
    border-top: 5px solid #08255d !important;
}

.row-module{
    position: relative;
    top: 10px;
}
.module-title-wrap {
    background: transparent;
}
.sc-iJuUWI svg{
    fill: black  !important;
}
.profile-page h3 {
    color: #2A206A;
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    position: relative;
    top: 0px;
}
.servers {
    display: none i !important;
}

.portal-logo {
    width: auto;
    height: 142px;
    position: relative;
    right: 68px;
    bottom: 7px;
}
.portal-logo-text{
    color: #ececec;
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    font-weight: bold;
    position: relative;
    bottom: 103px;
    left: 7px;
}
.portal-logo-text-bold{
    color: #f2f0ff;
    position: relative;
    bottom: 167px;
    left: 250px;
    font-weight: 600;
}
.register-portal-logo-text{
    color: #39394D ;
    position: relative;
    bottom: 127px;
    left: 14px;
}
.register-portal-logo-text-bold{
    color: #39394D ;
    position: relative;
    bottom: 151px;
    left: 181px;
    font-weight: 600;
}
.portal-logo-sub-text{
    color: #9dacc7;
    position: relative;
    bottom: 160px;
    left: 75px;
    font-size: 16px;
}
.login-spinner{
    position: relative;
    bottom: 56px;
    left: 260px;
    height: 23px;
    width: 23px;
    color: white;
    display: none;
}
.version-spinner{
    position: relative;
    left: 45%;
    height: 119px;
    width: 119px;
    color: #41444e;
    display: none;
    top: 100px;
}
.solution-spinner{
    position: relative;
    left: 45%;
    height: 119px;
    width: 119px;
    color: #41444e;
    display: none;
    top: 100px;
}
.module-spinner{
    position: relative;
    left: 45%;
    height: 119px;
    width: 119px;
    color: #41444e;
    display: none;
    top: 100px;
}
.module-spinner-search{
    position: relative;
    left: 45%;
    height: 36px;
    width: 36px;
    color: #41444e;
    display: none;
    top: 20px;
}
.search-date-input{
    float: right;
    position: relative;
    right: 20px;
    width: 137px;
    border: 1px solid #9e191d;
    padding: 5px;
    padding-left: 7px;
    opacity: 0.9;
    border-radius: 5px;
    margin-right: 35px;
    margin-left: 34px;
    bottom: 6px;
}
.search-user-date-label {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
}
.search-api-input {
    width: 200px;
    padding: 8px;
}
.search-btn{
    margin-right: .5rem;
    float: right;
    position: relative;
    right: 20px;
    margin-left: 10px;
    top: -3px;
    border-color: #484093;
    font-size: 0.95rem;
    background: #5F56BB !important;
    color: white;
    font-size: 0.95rem;
}
.search-btn-icon{
    position: relative;
    left: 6px;
    bottom: 2px;
}
.search-btn:active {
    color: #fff;
    background-color: #5F56BB;
    border-color: #5F56BB;
}
.search-btn:hover {
    color: #fff;
    background-color: #5F56BB;
    border-color: #5F56BB;
}
.encrypted-req{
    color: #c3ce2d !important;
    position: relative;
    top: 7px;
    font-weight: bold;
}
.encrypted-req-wrapper p{
    padding-top: 5px;
    color: #c3ce2d !important;
}

.search-result-badge{
    position: relative;
    margin-bottom: 8px;
    top: 36px;
    display: inline-block;
    left: 20px;
    margin-left: 3px;
    margin-right: 3px;
    background-color: #525f7f;
    cursor: pointer;
}
.search-result-badge:hover{
    opacity: 0.7;
    background-color: #29385c !important;
}
.badge-container{
    margin-top: 25px;
    margin-bottom: 10px;
}
.search-no-results{
    position: relative;
    top: 29px;
    left: 15px;
}
.renderedMarkdown > div > a {
    /* display: inline-block;
    float: right;
    position: relative;
    right: 39%;
    top: 4px;
    color: blue;
    font-weight: bold;
    text-decoration: underline; 
    <div><a href="#">Secured Version</a></div>
    */
}
.renderedMarkdown > div > input{
    float: right;
    width: 52%;
    position: relative;
    right: 94px;
    padding: 5px;
    bottom: 3px;
}
.renderedMarkdown > div > button{
    position: relative;
    right: 80px;
    bottom: 5px;
    margin-left: 10px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    padding: 5px 23px;
    transition: all .3s;
    border: 2px solid grey;
    border-radius: 4px;
    background: transparent;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    font-family: sans-serif;
    color: #3b4151;
    float: right;
}
.renderedMarkdown > div > button:hover{
    box-shadow: 0 0 5px rgb(0 0 0 / 30%);
}
.renderedMarkdown > div > div > button {
    position: relative;
    right: 29px;
    margin-left: 10px;
    bottom: 190px;
    padding: .225rem 1.25rem;
    float: right;
    font-size: 14px;
    font-weight: 700;
    padding: 5px 23px;
    transition: all .3s;
    border-color: #ff6060;
    background-color: transparent;
    font-family: sans-serif;
    color: #ff6060;
    border-radius: 7px;
    display: none;
}
.renderedMarkdown > div > div > button:hover{
    box-shadow: 0 0 5px rgb(0 0 0 / 30%);
}
#decrypted-response-json{
    overflow: auto;
    background: #333;
    height: 90%;
    border-radius: 3px;
}
.copy-decrypted-response-icon{
    position: relative;
    bottom: 6px;
    right: 6px;
}
.copy-decrypted-response:hover{
    background: transparent;
    box-shadow: 0 0 5px rgb(0 0 0 / 30%);
    color: #333;
}
.copy-decrypted-response{
    height: 24px;
    width: 24px;
    float: right;
    position: relative;
    right: 16px;
    top: 3px;
    background: transparent;
}
.copy-enecrypted-request{
    height: 24px;
    width: 24px;
    float: right;
    position: relative;
    right: 16px;
    top: 21px;
    background: transparent;
}
.decrypt-modal {
    visibility: hidden; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
.select-account {
    height: 38px;
    padding: 8px;
    border-radius: 5px;
}
  /* Modal Content/Box */
  .decrypt-modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    height: 59%;
  }
  
  /* The Close Button */
  .decrypt-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: relative;
    bottom: 20px;
  }
  
  .decrypt-close:hover,
  .decrypt-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .encrypt-modal {
    visibility: hidden; 
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
  }
  .encrypt-modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    height: 59%;
  }
  .encrypt-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: relative;
    bottom: 20px;
  }
  .encrypt-close:hover,
  .encrypt-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .textarea-encrypt-req{
    width: 76%;
    height: 139px;
    background-color: #333;
    color: #c3ce2d;
    position: relative;
    top: 13px;
    overflow: auto;
    resize: none;
  }
  .textarea-encrypted-req{
    width: 76%;
    height: 139px;
    background-color: #333;
    color: #c3ce2d;
    position: relative;
    top: 30px;
    overflow: auto;
    resize: none;
  }
  .textarea-encrypt-req-label{
    position: relative;
    bottom: 109px;
    color: black;
    font-weight: bold;
    margin-right: 10px;
  }
  .textarea-encrypted-req-label{
    position: relative;
    bottom: 87px;
    color: black;
    font-weight: bold;
    margin-right: 10px;
  }
  .encrypted-req-wrapper{
    height: 270px;
    overflow-x: auto;
    padding: 0.5em;
    background: rgb(51, 51, 51);
    color: #c3ce2d;
    position: relative;
    top:20px
}
.modal-encrypte-btn{
    font-size: 14px;
    font-weight: 700;
    padding: 5px 23px;
    transition: all .3s;
    border: 2px solid grey;
    border-radius: 4px;
    background: transparent;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    font-family: sans-serif;
    color: #3b4151;
    margin-left: 13px;
}
.modal-encrypte-cls{
    font-size: 14px;
    font-weight: 700;
    padding: 5px 23px;
    transition: all .3s;
    border: 2px solid #ff6060;
    border-radius: 4px;
    background: transparent;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    font-family: sans-serif;
    color: #ff6060;
}
.modal-encrypt-btn-wrap{
    position: relative;
    top: 50px;
    margin: auto;
    width: 210px;
}
.swagger-ui .renderedMarkdown a {
  color: blue;
  font-weight: bold;
  float: right;
  position: relative;
  bottom: 10px;
}
.demo-page{
    background-color: #b8c6db;
    background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%);  
}
.help-wrap{
    border: none;
}
.demo-section{
    padding-top: 5rem;
    padding-bottom: 7rem;
}
.demo-video-img{
        width: auto;
        height: 142px;
        position: relative;
        border: 8px solid rgb(231 232 234)
}
.demo-description{
    color: #32325d;
    text-align: justify;
    position: relative;
    top: 33px;
    left: 18px;
    font-weight: 400;
}
.demo-video-no-margin{
    margin: 0;
    padding: 0;
}
.demo-video-img-mask{
    position: relative;
    left: 103px;
    border: none;
    height: 46px;
    cursor: pointer;
    bottom: 95px;
}
.demo-video-img-mask:hover{
    opacity: 0.7;
}
.demo-title{
    font-weight: 500;
    line-height: 1.1;
    color: black !important;
    text-align: left !important;
    position: relative !important;
    top: -8px !important;
    font-size: 14px !important;
}
.demo-request-separator{
    margin-top: 5px;
    margin-bottom: 10px;
}
.demo-video-container{
    margin: 0;
    padding: 0;
    position: relative;
    right: 30px;
    width: 718px;
    bottom: 126px;
    height: 404px;
}

.demo-video-modal{
    max-width: 738px;
    max-height: 400px;
}
.demo-video-modal-height{
    height: 400px;
}
.demo-form-required{
    color: red;
}
.register-page{
    background-image: url('../img/bg.jpg') !important;
    background-repeat: round;
    background-origin: content-box;
    background-size: cover !important;
}
.privacy-policy{
    margin-top: 20px;
    color: #32325d;
}
.privacy-policy-link{
    color: blue;
}
.react-player-warp{
    position: relative;
    bottom: 30px;
    padding: 0px;
}
.user-search-warp {
    display: flex;
    align-items: center;
    gap: 15px;
}
.rdt_TableCell {
    font-weight: 600 !important;
    padding-right: 0px !important;
}
.swagger-ui table.model tr.property-row .star {
    color: red !important;
}
.pointer+span span:first-child, .pointer+span span:nth-child(2) {
    color: #fff;
}
.pointer + span span:nth-child(1) {
    color: white ;
}
.pointer + span span:nth-child(2) {
    color: white;
}
#select-case{
display: block;
position: relative;
bottom: 12px;
color: #3b4151;
font-weight: bold;
}
#key-value-token{
    background: #F4F4F7;
    box-shadow: 1px 1px #f1f1f3;
    position: relative;
    bottom: 14px;
}
#select-case-img{
height: 24px;
width: 24px;
float: left;
position: relative;
bottom: 15px;
margin-right: 8px;
}
.toolbox{
    position: absolute;
    bottom: 10px;
    cursor: pointer;
    color: #f67c1b;
}
.toolbox-modal-encrypt-textarea{
    width: 100%;
    position: relative;
    bottom: 10px;
    height: 201px;
    color: #f67c1b;
    background-color: black;
}
.toolbox-modal-encrypt-footer{
    margin-bottom: 10px;
    margin-right: 5px;
}
.v4-latest-version{
    background:  linear-gradient(35deg, #31208c,#2A206A)!important;
    color: white;
    border-color: #c8ccd3;
}
.container-users{
    max-width: 80% !important;
}
#field-size{
    color: #2dce89 !important;
    font-size: 12px !important;
    font-family: monospace !important;
    font-weight: 600 !important;
}
#field-size-value{
    color: #c2c0c0 !important;
    font-size: 12px !important;
    font-family: monospace !important;
    font-weight: 600 !important;
    font-style: italic !important;
    position: relative !important;
    bottom: 10px !important;
}
#field-description{
    position: relative !important;
    bottom: 8px !important;
}
#deprecated-label{
    color: darkred !important;
    position: relative;
    bottom: 49px;
    font-size: small !important;
    margin-bottom: 0px !important;
}
/** Patch Css for swagger-ui-react update to 4.15.5 **/
.opblock-summary-method{
    float: left !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    min-width: 80px !important;
    padding: 6px 15px !important;
    text-align: center !important;
    border-radius: 3px !important;
    background: #000 !important;
    text-shadow: 0 1px 0 rgb(0 0 0 / 10%) !important;
    font-family: sans-serif !important;
    color: #fff !important;
}
.swagger-ui .opblock .opblock-summary{
    display: grid !important;
    background: white !important;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    height: 43px !important;
}
.swagger-ui button {
    cursor: pointer;
    outline: none !important;
    background: transparent !important;
    border: navajowhite;
    border-radius: 4px;
}
.swagger-ui .opblock .opblock-summary-description {
    position: relative !important;
    bottom: 8px !important;
    text-align: right  !important;
    align-items: center  !important;
    word-break: break-word  !important;
    padding: 0 10px  !important;
    color: #3b4151  !important;
}
.swagger-ui .authorization__btn.unlocked {
    display: none !important;
}
.swagger-ui svg:not(:root) {
    display: none !important;
}
.swagger-ui .opblock .opblock-summary-path {
    position: relative !important;
    bottom: -7px !important;
    left: 4px;
    font-size: 10pt;
}
.swagger-ui section.models h4 span {
    color: orange !important;
}
.swagger-ui .model-title {
    color: orange !important;
}
.view-line-link .copy-to-clipboard{
    display: none !important;
    visibility: hidden !important;
    background: red !important;
}
.swagger-ui .opblock .opblock-summary:hover .view-line-link {
    visibility: hidden;
}
.swagger-ui button {
color: darkorange;
}
.swagger-ui .opblock .opblock-summary .view-line-link {
    background: transparent !important;
}
.swagger-ui .opblock.opblock-deprecated .opblock-summary-method {
    width: 85px !important;
}
.swagger-ui .opblock .opblock-summary-path__deprecated {
    color: black;
    position: relative !important;
    left: -1px !important;
    bottom: -7px !important;
}
.swagger-ui table.model tr.description {
    color: #2dce89;
    font-weight: 400;
    position: relative;
    top: 3px;
}
.yaml-download{
    position: relative;
    left: 30px;
    bottom: 2px;
    cursor: pointer;
    font-size: 20px;
}
.api-explorer{
    margin-top: 40px;
    width: 22%;
    height: 600px;
    display: inline-block;
    max-height: 600px;
    background:linear-gradient(90deg, #f8f9f9 100%, #FFFFFF 100%);
    border-right: 1px solid #dedfe6;
}
.api-explorer-details{
    margin-top: 40px;
    width: 61%;
    height: max-content;
    display: inline-block;
    background:linear-gradient(90deg, #f8f9f9 0%, #FFFFFF 0%);
    border-right: 1px solid #dedfe6;
    position: relative;
    left: 30px;
    vertical-align: top;
}
.api-explorer-select{
    /* margin: auto; */
    bottom: 300px;
    width: fit-content;
    height: 600px;
    max-height: 700px;
    /* background: linear-gradient(90deg, #f8f9f9 100%, #FFFFFF 100%); */
    /* border-right: 1px solid #dedfe6; */
    /* background: green !important; */
    display: inline-block;
    width: max-content;
    position: relative;
    left: 69px;
    vertical-align: middle;
}
.api-explorer-select h4{
  font-weight: bold;
}
.api-explorer-select P{
    font-weight: 300;
    font-size: 12px;
    color: black;
}
.swagger-editor{
    color: #3b2ce3;
    font-weight: bold;
}
.api-ref-1{
    font-weight: 300;
    font-size: 12px;
    color: black;
    position: absolute;
}
.api-explorer-container{

}
hr.solid-bottom{
    border-top: 1px solid #e3e3e3;
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    top:40px;
}
/* Solid border */
hr.solid {
    border-top: 1px solid #e3e3e3;
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    bottom: 16px;
}
.nav-link-inner--text{
    color: #071739 !important;
    display: inline-block;
}
.nav-link{
    color: #2A206A !important;
}
.nav-item{
    display: flex;
}
.card{
    background-color: #F5F7F8 !important;
}
.btn-login-acc{
    background: #2A206A  !important;
    border-color: #2A206A  !important;
}
.create-acc-card {
    border-top: 5px solid #071739 !important;
}
.btn-info:hover{
    border-color: #D4D2F5 !important;
}
.row-grid{
    padding: 10px;
    position: relative;
    bottom: 40px;
}
.treeview > .toggle {
    pointer-events: all;
    cursor: pointer;
}
.treeview > .toggle:hover {
    color: #63b1de;
    fill: #63b1de;
}
.tree-child-api:hover{
    opacity: 0.6 !important;
    cursor: pointer !important;
}
.treeview > .toggle {
  fill: #41588b  !important ;
}
.treeview > span{
    color: #434355 ;
}
.treeview > span:active{
    color: #b00000 !important;
    cursor: pointer !important;
    box-shadow: 0 2px #b00000;
}
.treeview > span:hover{
    cursor: pointer !important;
    color:#b00000;
}
/*** hide schema */
.swagger-ui section.models{
    display: none;
}
.wrapper > .col-12-desktop{
    position: relative;
    bottom: 62px;
}
.portal-api-logo {
    position: relative;
    left: 109%;
    bottom: 230px;
    width: 407px;
    opacity: 0.3;
    transform: rotate(20deg) !important;
    visibility: hidden;

}

.main-bg {
    background-repeat: round !important;
    background-size: cover !important;
}
.footer-first-txt {
    color: white !important;
    margin-top: 16px;
}
.btn-warning:hover{
    color: #fff !important;
    background-color: #071739;
    border-color: #071739
}
.btn-sign-out:hover{
    color: #071739 !important;
}
.btn-sign-out{
    border-color: #071739 !important;
    color: #071739 !important;
    background: white !important;
}
.web-meeting{
    height: 50px !important;
    position: relative !important;
    left: -6px !important;
    cursor:pointer;
    mix-blend-mode: multiply;
    visibility: hidden;
}
#show-swagger-ui{
    display: block;
}
#show-static-content{
    display: none ;
    background: white;
    position: relative;
}
.navbar-brand{
    position: relative;
    right: 11%;
    bottom: 3px;
}
.navbar-collapse{
    position: absolute;
    left: 30%;
}
.social-media-items{
    position: relative;
    left: 6%;
}
.api-version-wrapper{
    position: relative;
    top: 12px;
    cursor: pointer;
}
.api-version-wrapper:hover{
    opacity: 0.7px;
}
.api-version{
    height: 31px;
    font-size: 13px;
    font-weight: bold;
    padding: 3px;
    color: black;
    border: none;
    background: transparent;
}
.api-version > span{
    padding-left: 4px;
    font-size: 11px;
}
.api-version:hover{
    opacity: 0.7;
    font-size: 13px;
}
.api-version-latest{
    color: blue;
}
.api-version-deprecated{
    color: #ff0505;
    font-size: 9px !important;
}
code[class*="language-"], pre[class*="language-"]{
    color: rgb(245, 247, 250);
    color: rgb(245, 247, 250);
}
.api-explorer-sub-select{
    margin-top: 12px;
}
.swagger-ui .opblock .opblock-summary{
background: #183153 !important;
    border-radius: 3px;
}
.swagger-ui .opblock.opblock-post .opblock-summary {
    border-color: #737b83 !important;
}
.swagger-ui .opblock.opblock-patch .opblock-summary {
    border-color: #52606D !important;
}
.swagger-ui .opblock.opblock-delete .opblock-summary {
    border-color: #52606D !important;
}
.swagger-ui .opblock.opblock-put .opblock-summary {
    border-color: #52606D !important;
}
.swagger-ui .opblock.opblock-deprecated{
    margin: 0px;
}
.swagger-ui .opblock .opblock-summary-description{
    color: #f0f1f3  !important;
}
.swagger-ui .opblock .opblock-summary-path{
   color: #f0f1f3  !important;
}
.badge-patch{
    background: rgb(147, 82, 123) !important;
    width: 46px;
    font-size: 9px !important;
}
.badge-put{
    background: #59D883 !important;
    width: 46px;
    font-size: 9px !important;
}
.badge-post{
    width: 46px;
    font-size: 9px !important;
    background: rgb(0, 91, 226)!important;
}
.badge-get{
    background: #dbd433  !important;
    width: 46px;
    font-size: 9px;
}
.badge-delete{
    width: 46px;
    font-size: 9px;
    background: rgb(200, 54, 55) !important;
}
.alert:not(.alert-secondary) {
    color: #000;
}
.alert-warning {
    background-color: transparent !important;
    color: black;
}
.try-out__btn{
    color: #0065FB !important;
}
.swagger-ui button{
    color: #00bc83
}
.microlight{
    background: #183153 !important;
    color: #f0f1f3;
}
.opblock-section .opblock-section-request-body .model-box{
    background: #183153 !important;
}
/* to test*/
.swagger-ui {
    background: rgb(82, 96, 109);
}
.swagger-ui .info .title{
    color: rgb(245, 247, 250) !important;
    display: none;
}
.swagger-ui .info .renderedMarkdown p{
    color: rgb(245, 247, 250) !important;
    display: none;

}
.swagger-ui .opblock-tag{
    border-bottom: 1px solid rgb(82, 96, 109);
    background: #323F4B;
}
.swagger-ui .opblock-tag{
    color: rgb(245, 247, 250) !important;
}
.swagger-ui .opblock-tag:hover{
    background: #323F4B;
}
.api-explorer-details{
    background: rgb(82, 96, 109);
}
.swagger-ui .opblock .opblock-summary{
    background: #323F4B !important;
}
.swagger-ui .info .title small pre {
    color: rgb(158, 250, 167)!important;
}
.swagger-ui .opblock.opblock-post .opblock-summary-method {
    background: rgb(0, 91, 226)!important;
}
.opblock-body{
    background: rgb(82, 96, 109);
    color: rgb(245, 247, 250) !important;
}
.swagger-ui section h3{
    color: rgb(158, 250, 167) !important;
    font-size: 12pt;

}
.swagger-ui .opblock-description-wrapper p, .swagger-ui .opblock-external-docs-wrapper p, .swagger-ui .opblock-title_normal p {
    font-size: 13px !important;
    color: rgb(245, 247, 250) !important;
    text-align: justify;
    color: rgb(245, 247, 250) !important;
}
.swagger-ui .wrapper{
    bottom: 134px;
    right: 10px;
}
.swagger-ui .btn.authorize{
    position: absolute;
    margin-top: 24px;
    margin-left: -43px !important;
}
.swagger-ui .auth-wrapper {
    position: relative;
    top: 134px;
    left: 136px;
}
.swagger-ui .opblock.opblock-patch{
    background: transparent;
}
.swagger-ui .opblock.opblock-get{
    background: transparent;
}
.swagger-ui .opblock.opblock-put{
    background: transparent;
}
.swagger-ui .opblock.opblock-post{
    background: transparent;
}
.swagger-ui .highlight-code>.microlight{
    background: #323F4B !important;
}
.swagger-ui .opblock .opblock-summary-path {
    color: #ffc13b!important;
}
.swagger-ui .renderedMarkdown a{
    display: none;
}
.swagger-ui .opblock.opblock-patch .opblock-summary-method {
    background: rgb(147, 82, 123) !important;
}
.swagger-ui .opblock .opblock-section-header {
    background: #323F4B !important;
}
#select-case{
    color: rgb(245, 247, 250) !important;
}
.swagger-ui .opblock .opblock-section-header h4{
    color: rgb(245, 247, 250) !important;
}
.swagger-ui .markdown code, .swagger-ui .renderedMarkdown code{
    color: #ffc13b;
}
#key-value-token {
    background: transparent;
    box-shadow: none;
}
.swagger-ui button {
    color: rgb(158, 250, 167);
}
.response .renderedMarkdown p {
    color: #ffc13b!important;
    font-size: 11pt !important;
}
.swagger-ui .response-col_description__inner table tbody th{
    color: rgb(158, 250, 167) !important;
    box-shadow: none !important;
}
.swagger-ui .response-col_description {
    color: rgb(245, 247, 250) !important;
}
.swagger-ui .response-col_status {
    color: rgb(245, 247, 250) !important;
}
swagger-ui .response-col_description__inner table tbody th {
    border: 1px solid rgb(82, 96, 109) !important;
    box-shadow: none !important;
}
.swagger-ui .response-col_description__inner table tbody tr td {
    border: 1px solid rgb(82, 96, 109) !important;
    box-shadow: none !important;
}
.swagger-ui .response-col_description__inner table tbody th {
    border: 1px solid rgb(82, 96, 109) !important;
    box-shadow: none !important;
}
.swagger-ui .response-col_description__inner tbody > tr{
    color: rgb(245, 247, 250) !important;
    box-shadow: none !important;
}
.swagger-ui .response-col_description__inner tbody {
    color: rgb(245, 247, 250) !important;
    box-shadow: none !important;
    background: #323F4B;
}
.opblock-section .opblock-section-request-body .model-box {
    background: #323F4B !important;
}
.response-control-media-type__accept-message code {
    color: #ffc13b!important;
}
.swagger-ui b, .swagger-ui strong {
    color: #0065FB;
}
#select-case-img{
    display: none;
}
#deprecated-label{
    color: rgb(245, 247, 250) !important;
}
.renderedMarkdown img{
}
.swagger-ui .opblock-title_normal {
    color: #dbd433;
}
.swagger-ui .opblock-description-wrapper p, .swagger-ui .opblock-external-docs-wrapper p, .swagger-ui .opblock-title_normal p {
    font-size: 10pt !important;
}
.try-out__btn {
    color: rgb(245, 247, 250) !important;
}
.try-out button{
    background: #0065FB !important;
    border: 2px solid #0065FB;
}
.try-out button:hover{
    background: #0065FB !important;
    border: 2px solid #0065FB;
}
.swagger-ui .dialog-ux .modal-ux {
    background: #323F4B !important;
}
.swagger-ui .auth-container .wrapper label {
    color: rgb(245, 247, 250) !important;
    top: 114px;
}
.swagger-ui .auth-container .wrapper code {
    position: relative;
    top: 72px;
}

.swagger-ui .dialog-ux .modal-ux-content p {
    color: rgb(245, 247, 250) !important;
}
.swagger-ui .auth-container .wrapper .renderedMarkdown div table tbody tr td:first-of-type {
    color: #ffc13b;
}
.swagger-ui .dialog-ux .modal-ux-content p {
    position: relative;
    top: 60px;
    font-size: 10pt !important;
    margin-top: 22px;
}
.swagger-ui .dialog-ux .modal-ux-content table {
    position: relative;
    top: 72px;
    color: #ffc13b;
}
.swagger-ui .auth-container .wrapper .renderedMarkdown div h5 {
    position: relative;
    color: rgb(245, 247, 250) !important;
    top: 73px;
    right: 12px;
}
.swagger-ui .auth-container .wrapper .renderedMarkdown div table tbody tr td:last-child {
    color: #ffc13b !important;
}
.swagger-ui .auth-container input[type=password], .swagger-ui .auth-container input[type=text] {
    min-width: 230px;
    width: 100%;
    position: relative;
    top: 112px;
    border-color: rgb(82, 96, 109);
}
.swagger-ui .auth-btn-wrapper button {
    left: 206px;
    background: #0065FB !important;
    color: rgb(245, 247, 250) !important;
    border-color: #0065FB !important;
    text-transform: uppercase;
}
.swagger-ui .auth-btn-wrapper .authorize {
    left: 73px !important;
    top: 247px !important;
    color: rgb(245, 247, 250) !important;
    border-color: #0065FB !important;
}
.swagger-ui .opblock.opblock-get {
    border-color: #323F4B;
}
.swagger-ui .btn.cancel {
    border-color: #0065FB;
}
.swagger-ui .btn.execute {
    background: #0065FB !important;
    color: rgb(245, 247, 250) !important;
    border-color: #0065FB;
}
.microlight {
    background: #323F4B !important;
    color: #f0f1f3;
    max-width: 850px;
}
.swagger-ui .response-col_description{
    max-width: 800px;
}
.swagger-ui table thead tr td, .swagger-ui table thead tr th {
    color: rgb(158, 250, 167)!important;
}
.swagger-ui .parameter__name {
    font-size: 13px;
    font-weight: 400;
    margin-right: 0.75em;
    font-family: sans-serif;
    color: rgb(158, 250, 167)!important;
}
.swagger-ui .parameter__extension, .swagger-ui .parameter__in {
    font-size: 12px;
    font-style: italic;
    font-family: monospace;
    font-weight: 600;
    color: rgb(158, 250, 167)!important;
}
.swagger-ui .parameter__type {
    font-size: 12px;
    padding: 5px 0;
    font-family: monospace;
    font-weight: 600;
    color: rgb(158, 250, 167)!important;
}
.swagger-ui .responses-inner h4, .swagger-ui .responses-inner h5 {
    color: rgb(158, 250, 167)!important;
}
.swagger-ui .parameters-col_description div p{
    background-color: rgb(50, 63, 75);
    border: 0px;
    outline: 0px;
    border-radius: 4px;
    color: rgb(245, 247, 250) !important;
    padding: 2px 20px;
    font-size: 12px !important;
    line-height: 12px;
    cursor: pointer;
    min-width: 90px;
    height: 24px;
    width: 337px;
    text-align: left;
    right: 186px;
    position: relative;
}
.swagger-ui .response-control-media-type__accept-message {
    color: rgb(245, 247, 250) !important;
}
.swagger-ui .dialog-ux .modal-ux-header {
    border-bottom: 1px solid rgb(82, 96, 109);
}
.swagger-ui .btn {
    border: none !important;
}
.swagger-ui textarea{
    background-color: rgb(50, 63, 75);
}
.btn-clear{
    color: rgb(245, 247, 250) !important;
}
.swagger-ui .dialog-ux .modal-ux{
    border-color:  rgb(82, 96, 109);
}
.swagger-ui .opblock.opblock-post .opblock-summary {
    border-color: rgb(82, 96, 109) !important;
}
.swagger-ui .opblock.opblock-post .tab-header .tab-item.active h4 span:after {
    background: #dbd433 !important;
}
.renderedMarkdown img {
    border-color: rgb(82, 96, 109) !important;
}
.renderedMarkdown img:hover {
    /*mix-blend-mode: normal;
    filter: contrast(1);*/
}
.display-image{
    color: rgb(245, 247, 250) !important;
    position: absolute;
    left: 93%;
    top: 11%;
    z-index: 1;
}
.btn-info:hover {
    border-color: #212529 !important;
}
.client-sdk-title{
    position: relative;
    top: 27px;
}
.client-sdk-card{
    width:min-content;
    border-color: rgb(230 230 231);
    padding-bottom: 4px;
}
.btn-display-sdk{
    background: transparent;
    border-color: #52606D;
    box-shadow: none;
}
.sdk-select-lang{
    width: 200px;
    font-size: 12pt;
    background: transparent !important;
}
.sdk-card-body{
    background: #F4F6F9;
}
.sdk-generator{
    border: none;
    background: transparent;
    font-size: 7pt;
    margin: 0;
    padding: 0;
    text-align: right;
    position: relative;
    right: 5px;
    bottom: 2px;
}
.sdk-button-generate{
    position: relative;
    float: right;
    top: 11px;
    background: rgb(0, 91, 226)!important;
    color: white;
    font-size: 11px;
    border-color: rgb(230 230 231);
}
.sdk-button-generate:hover{
    color: white;
}
.sdk-progress{
position: relative;
    height: 16px;
    width: 16px;
    color: #52606D;
    top: 10px;
    left: 7px;
    display: none;
}
.sdk-kit{
    padding-bottom: 6px;
    font-size: 12px;
    font-weight: 600;
}
.about-txt1{
    text-align: center;
    font-size: 18px;
}
.about-img{
    vertical-align: middle;
    border-style: none;
    mix-blend-mode: darken;
    height: 201px;
}
.about-img-world{
width: 100%;
}
.card-event{
    margin-right: 6px;
}
.card-group-event{
    margin-top: 12px;
}
.card-event-footer{
    background: transparent;
    border: none;
    position: absolute;
    bottom: 0;
}
.btn-press-release-more{
    width: 235px;
    background: transparent;
    border: none;
    box-shadow: none;
    color: #2A206A !important;
}
.btn-event-more{
    width: 100%;
    margin: auto;
    background: transparent;
    border: none;
    box-shadow: none;
    color: #2A206A !important;
}
.card-event-body{
  height: 197px;
}
.dropdown-toggle:hover{
    background: linear-gradient(180deg,#f67c1b 0,#f67c1b) !important;
    padding: 6px;
    text-align: center;
    font-weight: bold;
}
.dropdown-item:hover, .dropdown-item:focus{
    background: linear-gradient(180deg,#f67c1b 0,#f67c1b) !important;
    color: black;
}
.nav-link{
  font-weight: 600 !important;
}
.video-play{
    padding-left: 8px !important;
}
.press-release-card{
    background: transparent !important;
    width: 100%;
    color: black !important;
}
iframe{
    width: 100%;
    height: 800px;
}
.swagger-ui .model .property.primitive {
    overflow: auto !important;
    display: block !important;
    max-width: 675px !important;
}
.swagger-ui .opblock.opblock-get {
    border-color: #525b64 !important;
}
.response .model-box {
    background: #323F4B!important;
}
.btn-press-release-more:hover {
    background: transparent !important;
}
.swagger-ui .curl-command .copy-to-clipboard{
    display: none;
}
.swagger-ui .copy-to-clipboard {
    display: none;
}
.swagger-ui .download-contents {
    right: 24px;
}
::selection {
    color: #695e24;
    background-color: transparent !important;
}
.swagger-ui .download-contents {
    display: none;
}
.swagger-ui .response-col_status .response-undocumented{
    display: none;
}
.swagger-ui .auth-btn-wrapper button {
    min-height: 30px;
}
.lost-password-txt{
    font-size: 9pt;
    text-align: justify;
}
#invalid-email{
    color: red;
    width: 300px !important;
    position: relative;
    bottom: 43px;
    display: block;
    text-align: left;
}
.btn-reset-password{
    position: relative;
    bottom: 37px;
    width: 205px;
    right: 49px;
}
.reset-spinner{
    position: relative;
    height: 23px;
    width: 23px;
    display: none;
    color: white;
    position: relative;
    bottom: 92px;
    left: 5px;
}
.change-password-msg{
    font-size: 11pt;
    font-weight: 600;
}

.swagger-ui .opblock .opblock-summary-description {
    display: none !important;
}
.webhooks-details{
    margin-top: 40px;
    width: 65%;
    height: max-content;
    display: inline-block;
    background:linear-gradient(90deg, #f8f9f9 0%, #FFFFFF 0%);
    border-right: 1px solid #dedfe6;
    position: relative;
    left: 30px;
    vertical-align: top;
    background: rgb(82, 96, 109);
}
.webhooks-details .swagger-ui .opblock.opblock-post .opblock-summary {
    display: none !important;
}
.webhooks-details .swagger-ui .opblock .opblock-section-header {

}
.webhooks-details  .opblock-description-wrapper p{

}
.webhooks-details .parameters-container {
  display: none;
}
.webhooks-details .swagger-ui b, .swagger-ui strong {
    color: #ffc13b!important;
}
.webhooks-details .microlight {
    max-width: 100% !important;
}
.webhooks-details .swagger-ui .opblock-description-wrapper, .swagger-ui .opblock-external-docs-wrapper, .swagger-ui .opblock-title_normal {
    padding: 7px 20px;
}

.webhooks-details .responses-header{
    position: relative;
    bottom: 50px;
}
.webhooks-details .swagger-ui section h3 {
   position: relative;
    top:4px;
    font-size: 9pt;
}
.webhooks-details .response {
    position: relative;
    bottom: 43px;
}
.webhooks-details .response .renderedMarkdown p {
    color: white !important;
    font-size: 11pt !important;
    position: relative;
    bottom: 19px;
}
.webhooks-details .response-col_status{
    top: 41px !important;
    position: relative;
    font-size: 12px;
    font-weight: 600;
}
.webhooks-register{
    color: white;
}
.webhooks-details .swagger-ui .opblock-tag.no-desc span {
    color: rgb(158, 250, 167);
    font-weight: 200;
}
.webhooks-details .swagger-ui .markdown pre, .swagger-ui .renderedMarkdown pre {
    position: relative;
    bottom: 11px;
    font-size: 8pt;
    color: #e3a864;
}
.webhooks-details .swagger-ui hr {
    padding: 0px;
    margin: 0px;
}
.webhooks-details .swagger-ui input {
    overflow: visible;
    width: 371px;
    height: 25px;
    border: 1px solid dimgray;
    border-radius: 4px;
    padding: 6px;
    font-weight: 600;
}
.webhooks-details .swagger-ui label {
    color: #caced3;
}
#webhooks_headers_table{
margin-top: -22px;
}
#webhooks-header-key{
    width: 200px;
    position: relative;
    bottom: 15px;
}
#webhooks-header-value{
    width: 500px;
    position: relative;
    bottom: 15px;
}

#webhook-response{
    position: relative;
    bottom: 16px;
}
.webhooks-h3{
    font-size: 9pt;
    color: rgb(158, 250, 167);
    position: relative;
    top: 21px;
    left: 22px;
}
.webhooks-table{
    width: 98%;
    font-size: 10pt;
    color: white;
    position: relative;
    bottom: 29px;
    background: rgb(50,63,75);
    margin: auto;
}
.webhooks-table-headers{
    width: 98%;
    font-size: 9pt;
    color: white;
    position: relative;
    bottom: 29px;
    background: #323F4B ;
    margin: auto;
    height: 95px;
}
.webhooks-td{
    width: fit-content;
    padding: 12px;
    font-size: 12px;
    position: relative;
    left: 10px;
}
.webhooks-body-param{
    position: relative;
}
.webhooks-url-input{
    height: 28px;
    width: 373px;
    font-size: 11px;
}
.webhooks-input-header{
    height: 28px;
    width: 245px;
    font-size: 11px;
    font-weight: 700;
    padding: 5px 40px 5px 10px;
    border: 1px solid #41444e;
    border-radius: 4px;
    background-size: 20px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.25);
    font-family: sans-serif;
    color: black !important;
    -webkit-appearance: none;
    appearance: none;
}
.webhooks-register-btn{
    position: relative;
    background: #005be2!important;
    border-color: #043d98;
}
.webhooks-register-btn:hover{
    border-color: #032f75 !important;
}
.webhooks-headers-container{
    position: relative;
    bottom: 48px;
}
.webhooks-headers-inputs{
    position: relative;
    left: 17px;
    bottom: 7px;
}
.webhooks-add-btn{
    position: relative;
    bottom: 1px;
    /* float: left; */
    width: 65px;
    background: #005be2!important;
    border-color: #043d98;
}
.webhooks-clear-btn{
    position: relative;
    bottom: 1px;
    /* float: left; */
    width: 63px;
    background: #2dce89!important;
    border-color: #2dce89;
}
.webhooks-clear-btn:hover{
    border-color: #2dce89 !important;
}
.webhooks-add-btn:hover{
    border-color: #032e75 !important;
}
.webhooks-details .swagger-ui{
    position: relative;
    bottom: 100px;
    right: 9px;
}
.webhooks-details .swagger-ui .scheme-container{
    display: none;
}
.webhooks-hr{
    margin-top: 22px;
}
.webhooks-label{
    font-size: 11px;
    position: relative;
    top: 16px;
}
.webhooks-td-2{

}
.webhooks-label-2{
}
.webhooks-card{
    border-bottom: 1px solid rgb(82, 96, 109) !important;
    background: #323F4B !important;
    height: 44px;
}
.event-title{
    font-size: 16px;
    text-align: left;
    color: white;
    padding: 9px;
    font-weight: bold;
}
#webhook-status-td{
    width: 200px;
}
.webhooks-details .form-control{
    color: black  !important;
}
.webhooks-details .swagger-ui .response-col_description__inner table tbody tr td {
    padding: 12px;
}
.webhooks-description{
    color: #f5f7fa;
    padding: 16px;
    font-size: 10pt;
    line-height: 1.7 !important;
    font-weight: 400;
    font-family: "Open Sans",sans-serif!important;
    padding-bottom: 0px;
}
.webhooks-headers-list{
    position: relative;
    bottom: 55px;
    color: darkorange;
    left: 28px;
    font-size: 10px;
}
.webhooks-details pre{
    color: #bcbcbc;
}
.webhooks-push-btn{
    position: relative;
    height: 29px;
    width: 80px;
    margin-right: 20px;
    background: #005be2!important;
    border-color: #043d98 !important;
    display: none;
}
.webhooks-push-btn:hover{
    background: #043d98!important;
    border-color: #043d98;
}
.webhooks-response{
    position: relative;
    bottom: 105px;
}
.webhook-response-code{
    font-size: 87.5%;
    color: orange;
    word-break: break-word;
    position: relative;
    bottom: 12px;
    height: 53px;
    display: inline-block;
    left: 22px;
}
.webhooks-details .swagger-ui .response-col_description__inner table tbody th{
    color: #9efaa7 !important;
    font-weight: normal !important;
}
.webhooks-details .swagger-ui .wrapper{
    bottom: 35px;
    right: 10px;
}
.webhooks-details .swagger-ui .opblock .tab-header {
    visibility: hidden;
}
.webhooks-details .responses-header {

}
.webhooks-details .swagger-ui .opblock-tag {
    background: transparent;
}
.webhooks-details .swagger-ui .opblock .opblock-section-header {
    position: relative;
    top: 18px;
    height: 46px;
}
.webhooks-details .swagger-ui .opblock-tag {
    background: transparent;
    position: relative;
    visibility: hidden !important;
}
.webhooks-details .body-param{
    position: relative;
    top: 18px;
}
.webhooks-details .swagger-ui .opblock .opblock-section-header h4 {
    position: relative;
    bottom: 54px;
    right: 11px;
}
.webhooks-details .swagger-ui .opblock-tag-section{
    position: relative;
    top: 55px;
}
.webhooks-details .swagger-ui .info {
    visibility: hidden;
    z-index: 0;
}
.webhooks-details .swagger-ui .opblock.opblock-post{
    position: relative;
    bottom: 115px;
}
.webhook-input-required{
    color:red
}
.webhook-input-required:after{
    font-size: 10px;
    position: relative;
    bottom: 24px;
    padding: 5px;
    content: "required";
    color: red;
    font-weight: bold;
}
.webhooks-td-flex{
    display: inline-flex;
}
.webhooks-details .swagger-ui .response-col_description {
    position: relative;
    top: 41px;
}
.webhooks-details .swagger-ui .responses-inner{
   position: relative;
    bottom: 39px;
}
.webhooks-details .swagger-ui .curl-command {
    position: relative;
    top: 35px;
}
.webhooks-details .request-url{
    display: none;
}
.webhooks-details .btn-clear{
    background: #2dce89!important;
    border-color: #2dce89;
}
.swagger-ui .opblock.opblock-delete .opblock-summary-method {
    background: linear-gradient(35deg, #F5365C 0, #f3103c 100%) !important;
}
swagger-ui .opblock.opblock-delete {
    background: transparent;
}
.swagger-ui .opblock-body pre.microlight{
    max-height: 324px !important;
    max-width: none !important;
}
.audit-details{
    position: relative;
    top: 23px;
    left: 9px;
}
.audit-search-warp {
    position: relative;
    margin-top: 13px;
    margin-bottom: 12px;
    left: 28%;
    top: 0px;
}
.audit-wrap {
    bottom: 23px !important;
    color: black !important;
}
.audit-wrap svg{
    fill: black !important;
}
.audit-wrap > .rdt_TableHeader{
    color: black !important;
}
.sc-dmlrTW{
    height: 410px;
}
.account-start-date-label{
    position: relative;
    display: inline-block;
    bottom: 8px;
}
.account-date-input{
    width: 137px;
    border: 1px solid #9e191d;
    padding: 5px;
    opacity: 0.9;
    border-radius: 5px;
    bottom: 6px;
    margin-right: 37px;
    margin-left: 5px;
}
.btn-enable-user-validty {
    position: relative;
}
.fa-file-pdf-o{
    color: white;
    font-size: 12px;
}

#li-color{
    color: rgb(158, 250, 167) !important;
}
#custom-link{
    display: flex;
    justify-content: flex-start !important;
    flex-direction: column !important;
    float: left;
    color: #1657ff;
}
#doc-table td {
    padding: 8px 20px;
    font-size: 11px;
    line-height: 1.4;
    white-space: normal;
    vertical-align: top;
    height: auto;
}